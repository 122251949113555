import Vue from 'vue'
import App from './App.vue'

import VueRouter from 'vue-router'
import Routes from './routes'
Vue.use(VueRouter)
const router = new VueRouter({
  routes: Routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash && !savedPosition) {
      return {
        selector: to.hash,
        behavior: 'smooth',
      }
    } else
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)

import Visual from 'vue-visual'
//const Visual = require('vue-visual')
Vue.component('vue-visual', Visual)
import 'vue-visual/index.css'

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  router
}).$mount('#app')
