import Home from './pages/Home.vue'
//import AllCollections from './pages/AllCollections.vue'
//import Products from './pages/AllProducts.vue'
import Contact from './pages/ContactUs.vue'
import BathKneelerGrey from './pages/BathKneelerGrey.vue'
import BathKneelerBeige from './pages/BathKneelerBeige.vue'
import BathKneelerWhite from './pages/BathKneelerWhite.vue'
//import BabyBath from './pages/BabyBathCollection.vue'
// import AutoMotive from './pages/AutoMotiveCollection.vue'
import AutoMotiveBelt from './pages/AutoMotiveBelt.vue'

/*const ItWorks = {
  template: '<div>It works!</div>'
}*/

export default [
  {path: '/', component: Home},
  //{path: '/collections', component: AllCollections},
  //{path: '/products', component: Products},
  {path: '/contact', component: Contact},
  {path: '/products/bath-kneeler-grey', component: BathKneelerGrey},
  {path: '/products/bath-kneeler-beige', component: BathKneelerBeige},
  {path: '/products/bath-kneeler-white', component: BathKneelerWhite},
  //{path: '/baby-bath-accessories', component: BabyBath},
  {path: '/auto-motive-accessories', component: AutoMotiveBelt}
]