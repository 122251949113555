<template>
  <div class="d-flex app-navbar" :class="{ 'app-navbar--scrolled': !isScrolledNavbar }">
    <b-navbar toggleable="sm" variant="faded" type="light" sticky  class="m-auto">
      <b-navbar-brand to="/" class="" style="">
        <b-img src="@/assets/baby-booth-logo.png" class="d-inline-block align-top" height="55" alt="baby-booth" title="baby-booth"></b-img>
      </b-navbar-brand>

      <b-navbar-toggle v-b-toggle.sidebar-1 target=""></b-navbar-toggle>

      <b-collapse id="nav-collapse" style="text-transform: uppercase; font-weight: bolder;" is-nav>
        <b-navbar-nav>
          <b-nav-item to="/">Home</b-nav-item>
          <b-nav-item-dropdown
            id="collection-menu-dropdown"
            text="Collections"
            toggle-class="nav-link-custom"
            right
          >
            <b-dropdown-item to='/products/bath-kneeler-beige' style="font-style: italic;text-transform: capitalize;">Bath Kneeler Beige</b-dropdown-item>
            <b-dropdown-item to='/products/bath-kneeler-grey' style="font-style: italic;text-transform: capitalize;">Bath Kneeler Grey</b-dropdown-item>
            <b-dropdown-item to='/products/bath-kneeler-white' style="font-style: italic;text-transform: capitalize;">Bath Kneeler White</b-dropdown-item>
          </b-nav-item-dropdown>
          <b-nav-item to="/contact">Contact Us</b-nav-item>
        </b-navbar-nav>

        <!-- Right aligned nav items -->
        
      </b-collapse>
    </b-navbar>
    <b-sidebar style="width: 280px;" id="sidebar-1" title="Babybooth Store" shadow>
      <div class="px-3 py-2 text-secondary">
        <b-navbar-nav>
          <b-nav-item to="/">Home</b-nav-item>
          <b-nav-item-dropdown
            style="color: gray;"
            id="collection-menu-dropdown"
            text="Collection"
            toggle-class="nav-link-custom"
            right
          >
            <b-dropdown-item to='/products/bath-kneeler-beige' style="font-style: italic;text-transform: capitalize;">Bath Kneeler Beige</b-dropdown-item>
            <b-dropdown-item to='/products/bath-kneeler-grey' style="font-style: italic;text-transform: capitalize;">Bath Kneeler Grey</b-dropdown-item>
            <b-dropdown-item to='/products/bath-kneeler-white' style="font-style: italic;text-transform: capitalize;">Bath Kneeler White</b-dropdown-item>
          </b-nav-item-dropdown>
          <b-nav-item to="/contact">Contact Us</b-nav-item>
        </b-navbar-nav>
      </div>
    </b-sidebar>
  </div>
</template>

<script>
export default {
  name: 'BabyBoothHeader',
  props: {
  },
  data() {
    return {
      isScrolledNavbar: false,
      lastScrollPosition: 0,
    }
  },
  mounted () {
    window.addEventListener('scroll', this.onScroll)
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.onScroll)
  },
  methods: {
    onScroll() {
      // Get the current scroll position
      const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop
      // Because of momentum scrolling on mobiles, we shouldn't continue if it is less than zero
      if (currentScrollPosition < 0) {
        return
      }
      // Here we determine whether we need to show or hide the navbar bottom part
      this.isScrolledNavbar = currentScrollPosition != 0
      // Set the current scroll position as the last scroll position
      this.lastScrollPosition = currentScrollPosition
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.app-navbar {
  position:sticky;
  top: 0;
  z-index: 1024; 
  background: white;
  box-shadow: 0 2px 2px -1px gray;
  transition: 0.3s all ease-out;
}
.app-navbar.app-navbar--scrolled {
  box-shadow: 0 0px 0px -1px gray;
}
.b-sidebar-body .nav-item a {
  color: gray;
  font-weight: bolder;
}
/*.navbar-collapse.collapsing {
  background-color: #faf6f3 !important;
  border-radius: 5px !important;
}
.navbar-collapse.collapsed { 
  background-color: #ffcba0 !important;
  border-radius: 5px !important;
}*/
</style>
<style>
.b-sidebar {
  width: 280px !important;
}
.b-sidebar-body .b-nav-dropdown a {
  color: gray;
  font-weight: bolder;
}
</style>
