<template>
  <div class="">
    <div class="row mt-3">
      <div class="col-sm-12 col-md-7 col-lg-7 text-center" id="first-col"  style="">
        <ProductViewer :images="images" />
      </div>
      <div class="col-sm-12 col-md-5 col-lg-5" id="second-col" style="margin-top: 75px;">
        <div class="text-center">
          <h1 class="product-title"><span>Pregnancy Seat</span> <span>Belt Adjuster</span></h1>
          <div style="font-size: 1.125em; font-weight: bolder; letter-spacing: .1em; margin-top: 20px; margin-bottom: -15px;">$7.99</div>
          <h2>____</h2>          
          <div style="padding:10px;" class="">
            
            <a href="#">
              <b-button variant="info">
              <b-img src="@/assets/ebay-logo.png" class="d-inline-block align-top" height="45" alt="baby booth on Ebay"></b-img>
              </b-button>
              <h6><u>Buy it on your trusted platform</u></h6>
            </a>
            
          </div>
          <b-button variant="info">
          <b-img src="@/assets/amazon-logo.png" class="d-inline-block align-top" height="45" alt="baby booth on Amazon"></b-img>
          </b-button>
        </div>
        <div>
          <strong>SAFETY FIRST FOR PREGNANT WOMEN!</strong> <br />
          <strong> COMFORTABLE AND SAFE CAR</strong> <br />
          <strong>JOURNEYS:</strong>
          Are you feeling uncomfortable pressure caused by the lap belt pressing into your
          belly? With the pregnancy seat belt adjuster, car rides are no longer
          accompanied by aches and stiffness. Danger to your unborn baby may be caused as
          the lap belt fits snug around you bump. Using the pregnancy seat belt positioner the
          lap belt adjusts and wraps comfortably around your thighs by removing pressure
          and discomfort from your belly.<br />
          <strong>BABY SAFETY:</strong><br />
          For EXTRA safety on the road we
          included child safety car seat accessories such as BABY ON BOARD car sticker to
          warn other of your little ones when on the go. A comfortable journey, safe ride and
          lifesaver baby belt!<br />
          <strong> FIT! FITS LIKE A GLOVE ON ANY CAR SEAT:</strong><br />
          Premium enhanced positioner hook to ensure seat belt clips and stays in place
          without breaking loose; fits tightly on your car seat for the best fit you’ve seen! It’s
          smooth edges and curved design, with an arch for perfect GRASP onto the lap belt
          You’ll forget the pregnancy seat belt holder even exists on your car seat, and able to
          concentrate more on the road and enjoy a pleasant ride in your maternity seat belt.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProductViewer from '../components/ProductViewer.vue'
export default {
  name: 'BabyBoothContactUs',
  components: {
    ProductViewer
  },
  props: {
  },
  data() {
    return {
      images: [
        {
          src: require('../assets/automotive/main-540x-1.jpg'),    // lightbox
          src300: require('../assets/automotive/main-540x-1.jpg'), // product viewer (one bigger image serves both thumbnails and images)
          alt: 'belt main'
        },
        {
          // this part for LightBox video
          type: 'video',
          sources: [
            {
              src: ''/*require('../assets/automotive/sba-video-trim-45s.mp4')*/,
              type: 'video/mp4'
            }
          ],
          width: 800, // Required
          height: 600, // Required
          autoplay: true,
          // this part for product viewer
          src300: require('../assets/automotive/video-300x.jpg'),
          alt: 'belt video',
          video: ''/*require('../assets/automotive/sba-video-trim-45s.mp4')*/
        },
        {
          src: require('../assets/automotive/belt-package-300x.jpg'),
          src300: require('../assets/automotive/belt-package-300x.jpg'),
          alt: 'belt package'
        },
        {
          src: require('../assets/automotive/belt-300x.jpg'),
          src300: require('../assets/automotive/belt-300x.jpg'),
          alt: 'belt'
        },
        {
          src: require('../assets/automotive/seat-cushion-hook-1-300x.jpg'),
          src300: require('../assets/automotive/seat-cushion-hook-1-300x.jpg'),
          alt: 'seat-cushion-hook1'
        },
        {
          src: require('../assets/automotive/seat-cushion-hook-2-300x.jpg'),
          src300: require('../assets/automotive/seat-cushion-hook-2-300x.jpg'),
          alt: 'seat-cushion-hook2'
        },
        {
          src: require('../assets/automotive/installation-step1-300x.jpg'),
          src300: require('../assets/automotive/installation-step1-300x.jpg'),
          alt: 'seat-cushion-hook3'
        },
        {
          src: require('../assets/automotive/installation-step2-300x.jpg'),
          src300: require('../assets/automotive/installation-step2-300x.jpg'),
          alt: 'seat-cushion-hook4'
        },
        {
          src: require('../assets/automotive/teeth-detail-300x.jpg'),
          src300: require('../assets/automotive/teeth-detail-300x.jpg'),
          alt: 'teeth-detail'
        },
        {
          src: require('../assets/automotive/installation-step3-300x.jpg'),
          src300: require('../assets/automotive/installation-step3-300x.jpg'),
          alt: 'seat-cushion-hook5'
        },
        {
          src: require('../assets/automotive/installation-step4-300x.jpg'),
          src300: require('../assets/automotive/installation-step4-300x.jpg'),
          alt: 'seat-cushion-hook6'
        },
        {
          src: require('../assets/automotive/floating-300x.jpg'),
          src300: require('../assets/automotive/floating-300x.jpg'),
          alt: 'belt floating'
        },
        {
          src: require('../assets/automotive/belt-logo-300x.jpg'),
          src300: require('../assets/automotive/belt-logo-300x.jpg'),
          alt: 'belt logo'
        }
      ]
    }
  },
  methods: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.product-title {
  max-width:300px;
  margin:auto;
}
.platform-logo:hover{
  background-color: rgba(255, 228, 196, 0.61);
}
</style>
<style>
</style>

