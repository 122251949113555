import axios from 'axios'
let api = axios.create({
  baseUrl: 'https://87sgko8b6l.execute-api.us-east-1.amazonaws.com/beta'
})

let defaultErrorResponse = function (error) {
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    console.error('[Baby Booth]')
    console.log(error.response.data)
    console.log(error.response.status)
    console.log(error.response.headers)
  } else {
    console.error('[Baby Booth] Could not receive a response. ' + error.message)
  }
}

export default class SiteApi {
  constructor (component) {
    this.component = component
  }

  postMessage (message, callback, errorCallback) {
    return api.request({
      method: 'post',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      url: `https://87sgko8b6l.execute-api.us-east-1.amazonaws.com/beta/message/`,
      //data: `{'name': '${message.name}', 'email': '${message.email}', 'subject': '${message.subject}', 'text': '${message.text}'}`
      data: message
    }).then(callback)
      .catch(function (error) {
        if (errorCallback) {
          errorCallback(error)
        }
        defaultErrorResponse(error)
      })
  }
}