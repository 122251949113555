<template>
  <div class="text-center">
    <!--controls
    indicators-->
    <b-carousel
      id="carousel-1"
      class="full-width carousel-center"
      fade
      :interval="0"
      background="#ababab"
      img-width="1024"
      img-height="480"
      style="text-shadow: 1px 1px 2px #333;"
    >
      <!-- Text slides with image -->
      <!--<b-carousel-slide
        caption="First slide"
        text="Nulla vitae elit libero, a pharetra augue mollis interdum."
        img-src="https://picsum.photos/1024/480/?image=52"
      ></b-carousel-slide>-->
      <!-- Slides with custom text -->
      <!--<b-carousel-slide img-src="https://picsum.photos/1024/480/?image=54">
        <h1>Hello world!</h1>
      </b-carousel-slide>-->
      <!-- Slides with image only -->
      <!--<b-carousel-slide img-src="https://picsum.photos/1024/480/?image=58"></b-carousel-slide>-->
      <!-- Slides with img slot -->
      <!-- Note the classes .d-block and .img-fluid to prevent browser default image alignment -->
      <!--<b-carousel-slide>
        <template v-slot:img>
          <img
            class="d-block img-fluid w-100"
            width="1024"
            height="480"
            src="https://picsum.photos/1024/480/?image=55"
            alt="image slot"
          >
        </template>
      </b-carousel-slide>-->
      <!-- Slide with blank fluid image to maintain slide aspect ratio -->
      <!-- <b-carousel-slide caption="Blank Image" img-blank img-alt="Blank image" style="" class="d-flex h-100 align-items-center justify-content-center">
        <div style="">
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse eros felis, tincidunt
          a tincidunt eget, convallis vel est. Ut pellentesque ut lacus vel interdum.
        </p>
        </div>
      </b-carousel-slide>-->
      <b-carousel-slide>
        <template v-slot:img>
          <b-overlay :show="true" :variant="'dark'" :opacity="0.35">
            <img
              class="d-block img-fluid w-100"
              :src="slide_1"
              alt="Baby Bath Accessories"
            >
            <template #overlay>
              <div></div>
            </template>
          </b-overlay>
        </template>
        <div class="d-flex h-100 align-items-center justify-content-center">
          <div>
            <h1>Baby Bath Accessories</h1>
            <b-button variant="info" @click="scrollToCollection()">VIEW COLLECTION</b-button>
          </div>
        </div>
      </b-carousel-slide>
      <!-- Note the classes .d-block and .img-fluid to prevent browser default image alignment -->
      <!--<b-carousel-slide>
        
        <template v-slot:img>
          <b-overlay :show="true" :variant="'dark'" :opacity="0.25">
            <img
              class="d-block img-fluid w-100"
              :src="slide_2"
              alt="Automotive Accessories"
            >
            <template #overlay>
              <div></div>
            </template>
          </b-overlay>
        </template>
        
        <div class="d-flex h-100 align-items-center justify-content-center">
          <div>
            <h1>Automotive Accessories</h1>
            <b-button variant="info" @click="navigateTo('auto-motive-accessories')">VIEW COLLECTION</b-button>
          </div>
        </div>
      </b-carousel-slide> -->
    </b-carousel>
    <h5 ref="div_collection" id="collections" class="mt-5">
      Designed by parents with comfort, safety, and durability in mind.
    </h5>
    <div class="mt-3">&nbsp;</div>
    <!-- <h2 class="mt-5">All Collections</h2>
    <h2>____</h2>
    <div class="d-flex flex-nowrap mt-5" style="gap: 25px;">
      <div class="collection-images-wrapper" @click="navigateTo('baby-bath-accessories')">
        <div class="collection-image" :style="`background-image: url('${bgBath}'); `">
          <h3>Baby Bath Accessories</h3></div>
      </div>
      <div class="collection-images-wrapper" @click="navigateTo('auto-motive-accessories')">
        <div class="collection-image" :style="`background-image: url('${bgAutomot}'); `">
          <h3>Automotive Accessories</h3>
        </div>
      </div>
    </div>
    <div class="mt-5">&nbsp;</div> -->
    <h2 class="mt-5">Featured Collection</h2>
    <h2 class="">____</h2>
    <div class="d-flex h-100 align-items-center justify-content-center featured-collection" style="gap: 20px;">
      <div class="flex-grow-1 featured-images-wrapper" @click="navigateTo('products/bath-kneeler-beige')">
      <b-overlay :show="true" :variant="'secondary'" :opacity="0.05">
        <div>
        <b-img-lazy v-bind="imageCommons" :src="getImage('bbgrey')" alt="Babybath Beige" fluid></b-img-lazy>
        </div>
        <template #overlay><h3>&nbsp;</h3></template>
      </b-overlay>
      <span>Bath kneeler and Elbow Rest, BEIGE - <strong>$44.99</strong></span>
      </div>
      <div class="flex-grow-1 featured-images-wrapper" @click="navigateTo('products/bath-kneeler-grey')">
        <b-overlay :show="true" :variant="'secondary'" :opacity="0.05">
          <div>
          <b-img-lazy v-bind="imageCommons" :src="getImage('bbbeige')" alt="Babybath Grey" fluid></b-img-lazy>
          </div>
          <template #overlay><h3>&nbsp;</h3></template>
        </b-overlay>
        <span>Bath kneeler and Elbow Rest, GREY - <strong>$44.99</strong></span>
      </div>
      <div class="flex-grow-1 featured-images-wrapper" @click="navigateTo('products/bath-kneeler-white')">
      <b-overlay :show="true" :variant="'secondary'" :opacity="0.05">
        <div>
        <b-img-lazy v-bind="imageCommons" :src="getImage('bbwhite')" alt="Babybath White" fluid></b-img-lazy>
        </div>
        <template #overlay><h3>&nbsp;</h3></template>
      </b-overlay>
      <span>Bath kneeler and Elbow Rest, WHITE - <strong>$44.99</strong></span>
      </div>
    </div>
    <div class="mt-5" id="featured-product">&nbsp;</div>
    <div class="row mt-3">
      <div class="col-sm-12 col-md-7 col-lg-7 text-center" id="first-col"  style="">
        <b-img-lazy :src="getImage('bbgrey_big')" alt="Babybath Grey" fluid></b-img-lazy>
      </div>
      <div class="col-sm-12 col-md-5 col-lg-5" id="second-col" style="margin-top: 75px;">
        <div class="text-center">
          <h2 class="product-title">Bath kneeler and Elbow Rest, GREY</h2>
          <div style="font-size: 1.125em; font-weight: bolder; letter-spacing: .1em; margin-top: 20px; margin-bottom: -15px;">$44.99</div>
          <h2>____</h2>
          <div style="padding:10px;" class="mb-5">
            
            <a href="https://www.amazon.com/Kneeler-Kneeling-Bathtub-Accessories-Organizer/dp/B07WQTV19X?ref_=ast_sto_dp&th=1&psc=1" target="_blank">
              <b-button variant="info">
               <b-img src="@/assets/amazon-logo.png" class="d-inline-block align-top" height="45" alt="baby booth on Amazon"></b-img>
              </b-button>
              <h6 class="mt-2"><u>Get it on your trusted platform</u></h6>
            </a>
          </div>
          <b-link to="/products/bath-kneeler-grey">Full details <b-icon icon="arrow-right" class="ml-1"></b-icon></b-link>
        </div>
      </div>
    </div>
    <div class="mt-5">&nbsp;</div>
    <div class="full-width bg-info mt-5 d-flex justify-content-center align-items-center">
      <div style="color:white; padding: 20px; margin: 20px 0 20px 0;">
        <h2>Subscribe to our newsletter</h2>
        <span>Promotions, new products and sales. Directly to your inbox.</span>
        <h2 class="mt-n3">____</h2>
        <b-form v-if="!isSubscribed" @submit.prevent="newsSubscribe">
        <b-input-group class="mt-4">
          <b-form-input type="email" placeholder="Your email"></b-form-input>
          <b-input-group-append>
            <b-button variant="dark" type="submit">SUBSCRIBE</b-button>
          </b-input-group-append>
        </b-input-group>
        </b-form>
        <b-form-input v-else placeholder="Thanks for subscribing!" style="color: #56ad6a; background-color: #ecfef0; border-color: #56ad6a;" disabled></b-form-input>
      </div>
    </div>
  </div>
</template>

<script>
import slide_1 from '@/assets/slide-1_1944x.jpg'
import slide_2 from '@/assets/slide-2_1944x.jpg'
import bgBath from '@/assets/bath/grey/main-540x-1.jpg'
import bgAutomot from '@/assets/automotive/main-540x-1.jpg'
export default {
  name: 'BabyBoothHome',
  props: {
  },
  data() {
    return {
      slide_1,
      slide_2,
      bgBath,
      bgAutomot,
      isSubscribed: false,
      imageCommons: {
        center: true,
        fluidGrow: true,
        blank: true,
        blankColor: '#bbb',
        class: 'my-5'
      }
    }
  },
  methods: {
    newsSubscribe() {
      this.isSubscribed = true
    },
    getImage(img) {
      let res = null
      switch (img) {
        case 'bbgrey':
          res = require('../assets/bath/grey/main-300x-1.jpg')
          break;
        case 'bbgrey_big':
          res = require('../assets/bath/grey/bath-1-590x.jpg')
          break;
        case 'bbbeige':
          res = require('../assets/bath/beige/main-300x-1.jpg')
          break;
        case 'bbwhite':
          res = require('../assets/bath/white/main-300x-1.jpg')
          break;

        default:
          break;
      }
      return res
    },
    navigateTo(path) {
      this.$router.push({ path: `/${path}` })
    },
    scrollToCollection() {
      const divCollection = this.$refs.div_collection
      divCollection.scrollIntoView({ behavior: "smooth" })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.full-width input:disabled::placeholder {
  color:#56ad6a;
}
.featured-images-wrapper {
  position: relative;
  display: block;
  overflow: hidden;
  height: 100%;
  width: 100%;
  cursor: pointer;
}
.featured-images-wrapper .b-overlay-wrap {
  margin-bottom: -2rem;
}
.featured-images-wrapper span {
  font-style: italic;
  font-size: 1.25em;
  color: gray;
}
.collection-images-wrapper {
  position: relative;
  display: block;
  overflow: hidden;
  height: 100%;
  width: 100%;
  cursor: pointer;
}
.collection-images-wrapper::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: .25;
    background-color: #000;
}
.collection-images-wrapper:hover .collection-image,
 .collection-images-wrapper:focus .collection-image {
  transform: scale(1.1);
  opacity: .70;
}
.collection-image {
  height: 310px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  -webkit-transition: all .8s ease;
  -moz-transition: all .8s ease;
  -ms-transition: all .8s ease;
  -o-transition: all .8s ease;
  transition: all .8s ease;
}
.collection-image h3 {
    color: #ffffff !important;
    text-align: center;
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    height: 50px;
    font-style: italic;
    z-index: 5;
    /*text-decoration: none;*/
}
.collection-images-wrapper:hover .collection-image h3,
 .collection-images-wrapper:focus .collection-image h3 {
  color:white!important;
  background-color: rgb(119, 119, 48);
  text-align: center;
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 50px;
  z-index: 5;
 }
 @media screen and (max-width: 768px) { .featured-collection {flex-wrap: wrap;} }
 @media screen and (max-width: 768px) { .collection-image {height: 150px;} }
</style>
<style>

</style>
<style lang="scss">
.carousel-center {
  .carousel-caption {
    top: 20px;
  }
}
.collection-images {
  /*width: 400px;
  height: 300px;
  object-fit: cover;*/
  border: 5px solid black;
  height: 310px !important;
}

</style>
