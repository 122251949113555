<template>
  <div class="">
    <div class="row mt-3">
      <div class="col-sm-12 col-md-7 col-lg-7 text-center" id="first-col"  style="">
        <ProductViewer :images="images" />
      </div>
      <div class="col-sm-12 col-md-5 col-lg-5" id="second-col" style="margin-top: 75px;">
        <div class="text-center">
          <h1 class="product-title">Bath kneeler and Elbow Rest, WHITE</h1>
          <div style="font-size: 1.125em; font-weight: bolder; letter-spacing: .1em; margin-top: 20px; margin-bottom: -15px;">$44.99</div>
          <h2>____</h2>
          <div style="padding:10px;" class="mb-5">
            
            <a href="https://www.amazon.com/Kneeler-Kneeling-Bathtub-Accessories-Organizer/dp/B08XX3BJ3C?ref_=ast_sto_dp&th=1&psc=1" target="_blank">
              <b-button variant="info">
               <b-img src="@/assets/amazon-logo.png" class="d-inline-block align-top" height="45" alt="baby booth on Amazon"></b-img>
              </b-button>
              <h6 class="mt-2"><u>Get it on your trusted platform</u></h6>
            </a>
            
          </div>
        </div>
        <div>
          <strong>BATH TIME LUXURY:</strong>
          Are you tired of aching knees and elbows EVERY day? With the EXTRA THICK Baby Bath Pad Set, bath time is no longer a painful chore.
           Even on those hard, unforgiving tile floors! Comes with kneeling pad, elbow pad and storage bag! Easy to clean and easy to dry. 
           A luxury time saver, knee saver and life saver!<br />
          <strong>LIKE KNEELING ON A CLOUD:</strong>
          1.7 inches of foam and 3mm of 100% non-toxic spongy neoprene sits between your knees and the floor, 
          for the most support of any kneeling mat you’ve seen! It’s 22in wide and longer than most (15in), 
          with a non-slip rubber backing for extra support. You’ll forget the floor even exists, and able to focus more on your 
          little one splashing about in the baby bathtub or infant bath seat!<br />
          <strong>LARGE FUNCTIONAL STORAGE:</strong>
          Your non-slip elbow support is also 1.75in thick and 22in wide for max comfort. 
          It also features 4x EXTRA-LARGE mesh pockets! At 9in tall, they provide larger and more durable storage than other brands 
          doubling as bath caddy for bottles, toys, and other baby accessories. It also has 8x safety suction cups on the back, so you 
          won’t slip around.<br />
          <strong>PREMIUM MADE, MACHINE WASHABLE:</strong>
          Our knee and elbow pads are made from water-friendly neoprene and memory foam. With handles and Velcro, you simply hang them up to air dry naturally. 
          And, washing is easy – cold gentle machine cycle. For storage or travel, pop your bathroom baby bath set in the storage bag that 
          comes with your order.<br />
          <strong>LIFETIME GUARANTEE:</strong>
          First, thank you to our customers who provided feedback encouraging us to make the largest, 
          most supportive padded mat for moms! Love bath time with your baby and without aching joints or we’ll refund every cent. 
          Also comes with our Lifetime manufacturing warranty and no questions. Order your set now.
        </div>
        <div class="d-flex justify-content-center mt-5 w-100 text-center" style="gap: 1em; font-size: smaller;">
          <b-link href="//www.facebook.com/sharer.php?u=https://babybooth.co/products/bath-kneeler-white" target="_blank"><b-icon icon="facebook" class="mr-2"></b-icon>Share</b-link>
          <b-link href="//twitter.com/share?text=Bath%20kneeler%20and%20Elbow%20Rest,%20GREY&url=https://babybooth.co/products/bath-kneeler-white" target="_blank"><b-icon icon="twitter" class="mr-2"></b-icon>Tweet</b-link>
          <b-link href="//pinterest.com/pin/create/button/?url=https://babybooth.co/products/bath-kneeler-white&media=//babybooth.co/bath-white-1-1024x.jpg?v=1622259204&description=Bath%20kneeler%20and%20Elbow%20Rest,%20WHITE" target="_blank">
            <b-icon icon="pin-angle-fill" class="mr-2"></b-icon>Pin it
          </b-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProductViewer from '../components/ProductViewer.vue'
export default {
  name: 'BathKneelerGrey',
  components: {
    ProductViewer
  },
  props: {
  },
  data() {
    return {
      images: [
        {
          src: require('../assets/bath/white/bath-1-1024x.jpg'),    // lightbox
          src300: require('../assets/bath/white/bath-1-590x.jpg'), // product viewer (one bigger image serves both thumbnails and images)
          alt: 'bath main'
        },
        {
          src: require('../assets/bath/white/bath-2-1024x.jpg'),
          src300: require('../assets/bath/white/bath-2-590x.jpg'),
          alt: 'bath 2'
        },
        {
          src: require('../assets/bath/white/bath-3-1024x.jpg'),
          src300: require('../assets/bath/white/bath-3-590x.jpg'),
          alt: 'bath 3'
        },
        {
          src: require('../assets/bath/white/bath-4-1024x.jpg'),
          src300: require('../assets/bath/white/bath-4-590x.jpg'),
          alt: 'bath 4'
        },
        {
          src: require('../assets/bath/white/bath-5-1024x.jpg'),
          src300: require('../assets/bath/white/bath-5-590x.jpg'),
          alt: 'bath 5'
        },
        {
          src: require('../assets/bath/white/bath-6-1024x.jpg'),
          src300: require('../assets/bath/white/bath-6-590x.jpg'),
          alt: 'bath 6'
        },
        {
          src: require('../assets/bath/white/bath-7-1024x-scrubber.jpg'),
          src300: require('../assets/bath/white/bath-7-590x-scrubber.jpg'),
          alt: 'bath 7'
        },
        {
          src: require('../assets/bath/white/bath-8-1024x-towel-duck.jpg'),
          src300: require('../assets/bath/white/bath-8-590x-towel-duck.jpg'),
          alt: 'bath 8'
        },
        {
          src: require('../assets/bath/white/bath-9-1024x-towel-white.jpg'),
          src300: require('../assets/bath/white/bath-9-590x-towel-white.jpg'),
          alt: 'bath 9'
        },
        {
          src: require('../assets/bath/white/bath-10-1024x-card.jpg'),
          src300: require('../assets/bath/white/bath-10-590x-card.jpg'),
          alt: 'bath 10'
        }
      ]
    }
  },
  methods: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.product-title {
  max-width:300px;
  margin:auto;
}
.platform-logo:hover{
  background-color: rgba(255, 228, 196, 0.61);
}
</style>
<style>
</style>

