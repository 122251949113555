
<template>
  <div>
    <div class="d-none d-sm-none d-md-block">
      <div class="d-flex flex-column" style="gap: 20px;">
        <div class="bb-viewer" style='position: relative;' @click="openLightbox(activeThumbIndex)">
          <vue-visual
            :aspect='0.5'
            :object-fit="'contain'"
            :image="activeImage"
            :video="activeVideo"
            controls
            autoplay
            />
        </div>
        <div class="bb-thumbnails d-flex flex-wrap" style="gap: 20px;">
          <b-img v-for="(image, index) in images" :key="index" 
            class="bg-white mb-2 mr-3 bb-thumb"
            :class="(activeThumbIndex == index) ? 'bb-thumb-active' : ''"
            fluid 
            :src="image.src300" 
            :alt="image.alt"
            @click="activeThumbIndex=index">
          </b-img>
        </div>
        <LightBox
          ref="lightbox"
          :media="images"
          :show-light-box="false"
        />
      </div>
    </div>
    <div class="d-md-none">
      <b-carousel 
        id="mainCarousel"
        controls
        indicators
        ref="myCarousel">
        <div v-for="(image, index) in images" :key="index*10">
          <b-carousel-slide
            v-if="!image.video"
            :img-src="image.src300"
          ></b-carousel-slide>
          <b-carousel-slide
            v-else
            >
            <template v-slot:img>
              <b-embed type="video" aspect="4by3" controls :poster="image.src">
                <source :src="image.video" type="video/mp4">
              </b-embed>
            </template>
          </b-carousel-slide>
        </div>
      </b-carousel>
    </div>
  </div>
</template>

<script>
import LightBox from 'vue-it-bigger'
import('vue-it-bigger/dist/vue-it-bigger.min.css')
export default {
  name: 'BabyBoothProductViewer',
  components: {LightBox},
  props: {
    images: Array,
  },
  data() {
    return {
      activeThumbIndex: 0,
    }
  },
  computed: {
    activeImage: function () {
      return this.images[this.activeThumbIndex].src300.includes('video')? '': this.images[this.activeThumbIndex].src300;
    },
    activeVideo: function () {
      return this.images[this.activeThumbIndex].src300.includes('video')? this.images[this.activeThumbIndex].video: '';
    }
  },
  methods: {
    openLightbox(index) {
      // eslint-disable-next-line 
      //debugger
      this.$refs.lightbox.showImage(index)
    }
  }
}
</script>

<style scoped>
.bb-thumb:hover {
  cursor: pointer;
}
.bb-thumb-active {
  filter: sepia(10%) hue-rotate(9deg) saturate(1) brightness(98%) ; 
  border: 2px solid rgb(226, 1, 1);
}
.bb-thumbnails .bb-thumb {
  width: 20%;
}
@media screen and (max-width: 1180px) { .bb-thumbnails .bb-thumb {width: 18%;} }
@media screen and (max-width: 916px) { .bb-thumbnails .bb-thumb {width: 25%;} }
</style>
<style>
.vv-aspect-shim {
  padding-top: 100% !important;
}
.vib-container {
  background-color: white !important;
}
.vib-thumbnail-wrapper {
  display: none !important;
}
</style>
<style lang="sass">
#mainCarousel
  .carousel-indicators
    bottom: auto
    margin-top: 10px
  .carousel-indicators li
    background-color: black
  .carousel-control-next-icon
    background-image: none
  .carousel-control-next-icon::after
    background-image: none
    content: ">"
    font-size: 55px
    color: black
  .carousel-control-prev-icon
    background-image: none
  .carousel-control-prev-icon::after
    background-image: none
    content: "<"
    font-size: 55px
    color: black
  .carousel-control-prev, .carousel-control-next
    top: auto
    display: none /* remove this line to show next/prev buttons */
</style>