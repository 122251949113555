<template>
  <div id="app">
    <Header />
    <div :style="`background-image: url('${bgBody}'); background-size: cover; /*background-attachment: fixed;*/`">
      <b-container>
        <router-view></router-view>
        <Footer />
      </b-container>
    </div>
  </div>
</template>

<script>
import Header from './components/Header.vue'
import Footer from './components/Footer.vue'
import bgBody from '@/assets/bg-body.jpg'
export default {
  name: 'App',
  components: {
    Header, Footer
  },
  data() {
    return {
      bgBody
    }
  } 
}
</script>

<style lang="scss">
// Import custom SASS variable overrides, or alternatively
// define your variable overrides here instead
@import 'assets/_custom-vars.scss';
// Import Bootstrap and BootstrapVue source SCSS files
@import '~bootstrap/scss/bootstrap.scss';
@import '~bootstrap-vue/src/index.scss';

// General style overrides and custom classes go here
.btn-info {
  color: $white;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*text-align: center;*/
  color: #2c3e50;
}
/*body, input, textarea, div, select {
  font-family: Arapey, serif !important;
}*/
a:link, a:visited, a:active {
  color: $gray-800;
  text-decoration: none;
  background: transparent;
}
a:hover {
  color: $gray-600;
  text-decoration: none;
  background: transparent;
}
.full-width {
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  overflow: hidden !important;
}
</style>
