<template>
  <div class="text-center">
    <div class="pt-5">
    <h1>Contact Us</h1>
    <h2 class=" mt-n3 mb-5">____</h2>
    <b-form @submit.prevent="onSubmit" class="text-center" style="margin-top: 5rem;">
      <div class="d-flex flex-column" style="gap: 0.7em; margin: auto; max-width: 700px;">
        <b-form-input v-if="isSuccess" placeholder="Thanks for contacting us. We'll get back to you in one to two business days." class="success-message" disabled></b-form-input>
        <b-form-input v-if="isError" placeholder="Something went wrong. Please try again later." class="error-message" disabled></b-form-input>
        <div class="d-flex flex-wrap" style="gap: 0.7em;">
          <b-form-input v-model="message.name" class="flex-grow-1" style="width: 12rem;" id="name" size="lg" placeholder="Name" autofocus required></b-form-input>
          <b-form-input v-model="message.email" class="flex-grow-1" style="width: 12rem;" id="email" size="lg" type="email" placeholder="Email" required></b-form-input>
        </div>
        <b-form-input v-model="message.subject" id="subject" size="lg" placeholder="Subject"></b-form-input>
        <b-form-textarea
          id="textarea"
          v-model="message.text"
          placeholder="Message"
          rows="10"
          max-rows="10"
          required
        ></b-form-textarea>
        <b-button class="flex-shrink-1" style="margin-left: auto; width: fit-content;" variant="info" size="lg" type="sumbit">
          <span v-if="isSending"><b-icon icon="arrow-clockwise" animation="spin" font-scale="1"></b-icon><span class="ml-2">SEND</span></span>
          <span v-else class="">SEND</span>
        </b-button>
      </div>
    </b-form>
    </div>
  </div>
</template>

<script>
import SiteApi from '../siteAPI'
let api = new SiteApi()

export default {
  name: 'BabyBoothContactUs',
  props: {
  },
  data() {
    return {
      message: {name: '', email: '', subject: '', text: ''},
      isSuccess: false,
      isError: false,
      isSending: false,
    }
  },
  mounted () {
    this.isSuccess = this.isError = false
  },
  methods: {
    onSubmit() {
      // eslint-disable-next-line 
      //debugger
      var self = this
      self.message.name = self.message.name.replace(/'/g, "\\'")
      self.message.subject = self.message.subject.replace(/'/g, "\\'")
      self.message.text = self.message.text.replace(/'/g, "\\'")

      self.isSending = true
      api.postMessage(self.message, 
        function () {
          self.isSuccess = true
          console.log('[Post Message] SUCCESS')
          self.isSending = false
        }, 
        function (error) {
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.error('[Baby Booth]')
            console.log(JSON.stringify(error.response))
            // short version of the response can be from "error.response.data.errorMessage" object
          } else {
            console.error('[Baby Booth]' + error.message)
          }
          self.isError = true
          self.isSending = false
        }
      )
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.form-control {
    border: 0;
    background-color: rgb(248, 248, 248);
    font-style: italic;
    font-size: 1rem;
}
.success-message { 
  background-color: #ecfef0; 
  border: 1px solid rgb(86, 173, 106);
}
.success-message:disabled::placeholder {
  color:#56ad6a;
}
.error-message { 
  background-color: rgb(253 238 238); 
  border: 1px solid rgb(173 86 86);
}
.error-message:disabled::placeholder {
  color:#f30202;
}
</style>
<style>
</style>
