<template>
  <div class="text-center">
    <h2 class="mt-3">____</h2>
    <div class="mb-5 d-flex justify-content-between footer">
      <div class="mb-5 d-flex flex-nowrap flex-grow-1 w-100" style="justify-content: space-evenly;">
        <div class="d-flex flex-column align-items-start mt-4" style="gap: 0.5em; margin-right: 100px;">
          <b-link to="/">Home</b-link>
          <b-link to="/#collections">Collections</b-link>
          <b-link to="/#featured-product">Featured</b-link>
          <b-link to="/contact">Contact Us</b-link>
        </div>
        <div class="d-flex flex-column align-items-start mt-4" style="gap: 0.5em; /*margin: auto;*/">
          <b-link href="//www.facebook.com/sharer.php?u=https://babybooth.co/products/bath-kneeler-white" target="_blank"><b-icon icon="facebook" class="mr-2"></b-icon>Facebook</b-link>
          <b-link href="//twitter.com/share?text=Bath%20kneeler%20and%20Elbow%20Rest,%20GREY&url=https://babybooth.co/products/bath-kneeler-white" target="_blank"><b-icon icon="twitter" class="mr-2"></b-icon>Twitter</b-link>
          <b-link href="https://www.instagram.com/scrapersnbots/" target="_blank"><b-icon icon="instagram" class="mr-2"></b-icon>Instagram</b-link>
          <b-link href="https://www.youtube.com/" target="_blank"><b-icon icon="youtube" class="mr-2"></b-icon>YouTube</b-link>
        </div>
      </div>
      <div class="mt-4 company-name">
        © {{year}}, BABY BOOTH<span style="color: rgba(0, 0, 0, 0.0);">, Importol Inc.</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BabyBoothFooter',
  props: {
  },
  computed: {
    year: function () {
      return new Date().getFullYear();
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@media screen and (max-width: 768px) { .footer {flex-direction: column;  align-items: center; } .company-name {margin-left: 75px;} }
@media screen and (min-width: 820px) { .company-name {margin-right: 50px;} }
@media screen and (min-width: 1180px) { .company-name {margin-right: 100px;} }
</style>
<style>
</style>
